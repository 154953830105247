export interface FeeProgressItem {
  title: string;
  completed?: boolean;
  active?: boolean;
  order?: number;
}
export enum FeeScenario {
  examFee,
  printCertificateFee,
}
