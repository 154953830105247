import { Injectable } from '@angular/core';
import { GlobalParameterModel, ImageModel, ParameterCodeEnum } from '@lms/shared/models';

@Injectable({ providedIn: 'root' })
export class ParameterStorage {
  private dateFormat: string | null = null;
  private datetimeFormat: string | null = null;
  private paginationLimit: number | null = null;
  private maxUploadFileSize: string | null = null;
  private instructorDefaultImage: ImageModel | null = null;
  private userDefaultImage: ImageModel | null = null;
  private categoryDefaultImage: ImageModel | null = null;
  private videoDefaultImage: ImageModel | null = null;
  private courseDefaultImage: ImageModel | null = null;
  private checksum: string | null = null;
  private examRetakePrice: number | null = null;
  private stripeTaxSwitch: number | null = null;
  private lmsTaxSwitch: number | null = null;
  private supportEmail: string | null = null;

  getDateFormat(): string {
    return this.dateFormat || 'MMM dd, yyyy';
  }

  getDatetimeFormat(): string {
    return this.datetimeFormat || 'MMM dd, yyyy HH:mm';
  }

  getPaginationLimit(): number {
    return this.paginationLimit || 20;
  }

  getExamRetakePrice(): number {
    return this.examRetakePrice || 0;
  }

  getMaxUploadFileSize(): string {
    return this.maxUploadFileSize || '100MB';
  }

  getInstructorDefaultImage(): ImageModel | null {
    return this.instructorDefaultImage;
  }

  getUserDefaultImage(): ImageModel | null {
    return this.userDefaultImage;
  }

  getCategoryDefaultImage(): ImageModel | null {
    return this.categoryDefaultImage;
  }

  getVideoDefaultImage(): ImageModel | null {
    return this.videoDefaultImage;
  }

  getCourseDefaultImage(): ImageModel | null {
    return this.courseDefaultImage;
  }

  getTaxSwitch(): boolean {
    return this.stripeTaxSwitch === 1 || this.lmsTaxSwitch === 1;
  }

  getSupportEmail(): string | null {
    return this.supportEmail;
  }

  getChecksum(): string {
    return this.checksum || 'UNKNOWN';
  }

  setParameters(parameters: GlobalParameterModel[], parametersChecksum: string): void {
    for (const id in parameters) {
      if (parameters[id].code === ParameterCodeEnum.DATE_FORMAT) {
        this.dateFormat = parameters[id].value;
      }
      if (parameters[id].code === ParameterCodeEnum.DATETIME_FORMAT) {
        this.datetimeFormat = parameters[id].value;
      }
      if (parameters[id].code === ParameterCodeEnum.PAGINATION_LIMIT) {
        this.paginationLimit = parameters[id].value;
      }
      if (parameters[id].code === ParameterCodeEnum.MAX_UPLOAD_FILE_SIZE) {
        this.maxUploadFileSize = parameters[id].value;
      }
      if (parameters[id].code === ParameterCodeEnum.EXAM_RETAKE_PRICE) {
        this.examRetakePrice = parameters[id].value;
      }
      if (parameters[id].code === ParameterCodeEnum.STRIPE_TAX_SWITCH) {
        this.stripeTaxSwitch = +parameters[id].value;
      }
      if (parameters[id].code === ParameterCodeEnum.LMS_TAX_SWITCH) {
        this.lmsTaxSwitch = +parameters[id].value;
      }
      if (parameters[id].code === ParameterCodeEnum.SUPPORT_EMAIL) {
        this.supportEmail = parameters[id].value;
      }
    }
    this.checksum = parametersChecksum;
  }
}
