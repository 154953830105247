export enum Routing {
  DASHBOARD_B2B = 'home',
  DASHBOARD = 'dashboard',
  ROOM = 'room',
  DASHBOARD_B2B_START = '/home/start',
  LEARNING_PATH = 'learning-paths',
  ASSIGNED_TRAINING = 'assigned-training',
  NOT_FOUND = '/error/404',
  ACCESS_RESTRICTED = '/error/restricted',
}
