export interface DictionaryEnumModel {
  value: string;
  label: string;
}

export enum DictionaryEnum {
  // enums
  STRIPE_INVOICE_STATUS = 'stripeInvoiceStatus',
  PAYPAL_INVOICE_STATUS = 'paypalInvoiceStatus',
  PAYMENT_STATUS = 'paymentStatus',
  ENROLLMENT_STATUS = 'enrollmentStatus',
  BUNDLE_TYPE = 'bundleType',
  BUNDLE_STATUS = 'bundleStatus',
  COURSE_STATUS = 'courseStatus',
  COURSE_TYPE = 'courseType',
  COURSE_LEVEL = 'courseLevel',
  COURSE_PRICE_INTERVAL = 'coursePriceInterval',
  COURSE_PRICE_CATEGORY = 'coursePriceCategory',
  COURSE_PRICE = 'coursePrice',
  COURSE_PRICE_FLOW = 'coursePriceFlowEndpoint',
  COURSE_LEARNING_STATUS = 'courseLearningStatus',
  EXTERNAL_WEBHOOK_STATUS = 'externalWebhookStatus',
  EXTERNAL_WEBHOOK_PROVIDER = 'externalWebhookProvider',
  INSIGHTS_TIME_SPENT_INTERVAL = 'insightsTimeSpentInterval',
  TIMEZONES_MAPPING = 'timezonesMapping',
  CPE_ACCREDITED = 'cpeAccredited',
  TEMPLATE_PRICE = 'templatePlan',
  // autocompletes
  COURSE_CATEGORY = 'courseCategory',
  // static
  LANGUAGE = 'language',
}

export interface EnumsDictionaryModel {
  DictionaryEnum: Array<DictionaryEnumModel>;
}
