import { Injectable } from '@angular/core';
import { SubscriptionModel, SubscriptionNotificationModel } from '@lms/shared/models';
import { CurrentUserStorage } from './current-user.storage';

export const FULL_IMMERSION_SUB_NAME = 'Full-Immersion Subscription';
export const SELF_STUDY_IMMERSION_SUB_NAME = 'Self-Study Subscription';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
  private userSubscriptions: SubscriptionModel[] = this.currentUserStorage.getCurrentUser()?.subscriptions || [];
  notifications: SubscriptionNotificationModel[] = [];

  constructor(private currentUserStorage: CurrentUserStorage) {}

  hasNoSubscriptions(): boolean {
    return !this.currentUserStorage.isUserSubscriber();
  }

  activeSubscription(): SubscriptionModel | null {
    if (this.hasNoSubscriptions()) {
      return null;
    }

    const mainSubscription: SubscriptionModel | undefined = this.userSubscriptions.find(
      (subscription: SubscriptionModel) =>
        subscription.name === FULL_IMMERSION_SUB_NAME || subscription.name === SELF_STUDY_IMMERSION_SUB_NAME,
    );

    if (mainSubscription) {
      return mainSubscription;
    }

    return this.userSubscriptions[0];
  }

  getUserNotifications(): SubscriptionNotificationModel[] {
    const notifications: SubscriptionNotificationModel[] = [];
    const user = this.currentUserStorage.getCurrentUser();
    user?.subscriptions?.map(subscription => notifications.concat(subscription.notifications));
    return notifications;
  }
}
