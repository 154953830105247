import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserCodeComponent } from './user-code/user-code.component';

@NgModule({
  imports: [CommonModule, RouterModule.forChild([{ path: '', component: UserCodeComponent }])],
  declarations: [UserCodeComponent],
})
export class DashboardUiUserCodeModule {}
