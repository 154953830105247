import { Company, UserModel } from '@lms/shared/models';
import { COMPANIES_KEY } from '@lms/shared/constants';

export const defaultCompany = {
  name: 'CFI',
  label: 'Member Dashboard',
  slug: 'cfi',
  b2bLayoutEnabled: true,
  id: 0,
};

export function GetActiveCompany(user: UserModel | null): Company {
  const storedCompany = localStorage.getItem(COMPANIES_KEY);
  const parsedCompany: Company = storedCompany ? JSON.parse(storedCompany) : null;
  const companyListForUser = user?.companies?.filter((company: Company) => company.b2bLayoutEnabled);
  const parsedCompanyAvailableForUser = companyListForUser?.some(company => company.id === parsedCompany?.id);

  if (parsedCompany && parsedCompanyAvailableForUser) {
    return parsedCompany;
  }

  if (!parsedCompany && companyListForUser?.length) {
    return companyListForUser[0];
  }

  return defaultCompany;
}
