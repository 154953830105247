import { Injectable } from '@angular/core';

export const REDIRECT_TO_COMMUNITY_LINK_FLAG: string = 'REDIRECT_TO_COMMUNITY_LINK';

@Injectable({ providedIn: 'root' })
export class RedirectToCommunityLinkStorageService {
  setValue(value: string): void {
    localStorage.setItem(REDIRECT_TO_COMMUNITY_LINK_FLAG, JSON.stringify(value));
  }

  getValue(): string | null {
    const value = localStorage.getItem(REDIRECT_TO_COMMUNITY_LINK_FLAG);
    return value !== null && value !== 'undefined' ? JSON.parse(value) : null;
  }

  deleteValue(): void {
    localStorage.removeItem(REDIRECT_TO_COMMUNITY_LINK_FLAG);
  }
}
