export enum FileTypeCodeEnum {
  CATEGORY_IMAGE = 'CATEGORY_IMAGE',
  COURSE_IMAGE = 'COURSE_IMAGE',
  BUNDLE_IMAGE = 'BUNDLE_IMAGE',
  BUNDLE_BROCHURE_FILE = 'BUNDLE_BROCHURE_FILE',
  INSTRUCTOR_AVATAR = 'INSTRUCTOR_AVATAR',
  USER_AVATAR = 'USER_AVATAR',
  VIDEO_THUMBNAIL = 'VIDEO_THUMBNAIL',
  VIDEO_CAPTION = 'VIDEO_CAPTION',
  LESSON_CONTENT_PDF = 'LESSON_CONTENT_PDF',
  LESSON_CONTENT_DOWNLOAD = 'LESSON_CONTENT_DOWNLOAD',
  LESSON_CONTENT_VIDEO = 'LESSON_CONTENT_VIDEO',
  LESSON_CONTENT_AUDIO = 'LESSON_CONTENT_AUDIO',
  LESSON_CONTENT_MULTIMEDIA_EXTERNAL_URL = 'LESSON_CONTENT_MULTIMEDIA_EXTERNAL_URL',
  LESSON_CONTENT_MULTIMEDIA_ZIP_URL = 'LESSON_CONTENT_MULTIMEDIA_ZIP_URL',
  LESSON_CONTENT_MULTIMEDIA_ZIP = 'LESSON_CONTENT_MULTIMEDIA_ZIP',
  BULK_LESSONS_IMPORT = 'BULK_LESSONS_IMPORT',
  BUNDLE_REVIEWER_AVATAR = 'BUNDLE_REVIEWER_AVATAR',
  QUESTION_ATTACHMENT = 'QUESTION_ATTACHMENT',
  FEEDBACK_ATTACHMENT = 'FEEDBACK_ATTACHMENT',
  RESUME_FEEDBACK_ATTACHMENT = 'RESUME_FEEDBACK_ATTACHMENT',
}
