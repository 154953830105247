export interface UserEvent {
  eventName: string;
  linkName: string;
  linkLocation: string;
  pageSource: string;
  pageDestination: string;
  planName: string;
}

export class UserEventClass implements UserEvent {
  constructor(
    public eventName = '',
    public linkName = '',
    public linkLocation = '',
    public pageSource = '',
    public pageDestination = '',
    public planName = '',
  ) {}
}
