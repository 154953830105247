<article class="container_box">
  <section class="title_box">
    <div class="container">
      <div>
        <div class="title">
          <h1>Your ID</h1>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="container">
      <div class="card_box top-box">
        <div>Your unique ID is {{ EncryptDecryptService.encryptMD5(id).substring(0, 16) }}</div>
      </div>
    </div>
  </section>
</article>
