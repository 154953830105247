import { Inject, Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';

@Injectable({
  providedIn: 'root',
})
export class EncryptDecryptService {
  secretKey = 'YourSecretKeyForEncryption&Descryption';

  constructor(@Inject(ENVIRONMENT_TOKEN) public environment: Environment) {
    this.secretKey = environment.secretKeyAES || 'YourSecretKeyForEncryption&Descryption';
  }

  encryptAES(value: string): string {
    return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
  }

  decryptAES(textToDecrypt: string): string {
    return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
  }
  encryptMD5(value: string): string {
    return CryptoJS.MD5(value).toString();
  }
  encryptSHA1(value: string): string {
    return CryptoJS.SHA1(value).toString();
  }
}
