import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TwoFaCompletedStorageService {
  private twoFaCompleted = true;

  setState(state: boolean): void {
    this.twoFaCompleted = state;
  }

  getState(): boolean {
    return this.twoFaCompleted;
  }
}
