import { PaymentStatusEnum } from './enum';
import { PriceModel, PricingPlanEnum } from '../pricing-plan';
import { PaymentMethodModel } from './payment-method.model';
import { SubscriptionNotificationModel } from '../notifier/subscription-notification.model';

export interface OrderModel {
  uuid: string;
  id: number;
  orderId: string;
  productId: number;
  name: string;
  createdAt: string;
  type: OrderTypeEnum;
  originalPrice: null | PriceModel;
  totalPaid: null | PriceModel;
  beforeDiscount: null | PriceModel;
  revenue: null | PriceModel;
  maxioRemittance: boolean;
  discount: null | PriceModel;
  chargeAmount: null | PriceModel;
  tax: null | PriceModel; // tax from orders table in DB
  totalTax: null | PriceModel; // calculated based on invoices that connected to paypal and stripe accounts
  paymentStatus: PaymentStatusEnum;
  pricingPlanType: PricingPlanEnum;
  couponCode: null | string;
  paymentCountry: null | string;
}

export interface SubscriptionModel {
  id: number;
  uuid: string;
  name: string;
  createdAt: string;
  renewsAt: string;
  renewAmount: null | PriceModel;
  paymentDeadlineAt: null | string;
  refDate: string;
  billingAt: string;
  expiresAt: string;
  paymentMethod: null | PaymentMethodModel;
  paymentStatus: PaymentStatusEnum;
  isStripe: boolean;
  // Represent old Paypal payment method. This payment method doesn't exist anymore, however this flag is used to provide billing orders information for users who have paid by it.
  isPaypal: boolean;
  notifications: SubscriptionNotificationModel[];
  proposeCancellationDiscount: boolean;
  type: OrderTypeEnum;
}

export enum OrderTypeEnum {
  STRIPE = 'STRIPE',
  PAYPAL = 'PAYPAL',
  FREE = 'FREE',
  MANUAL = 'MANUAL',
  LEGACY = 'LEGACY',
  MAXIO = 'MAXIO',
  MOBILE = 'MOBILE',
}

export enum OrderTypeForHelpScoutEnum {
  STRIPE = 'STRIPE',
  PAYPAL = 'PAYPAL',
  MAXIO = 'MAXIO',
}
