export type Weekday = 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat';

export interface Day {
  day: Weekday;
  type: StreakStatus;
}

export interface UserStreak {
  state: StreakState;
  duration: number;
  freezeDaysLeft: number;
  grantedFreezeDaysLeft: number;
  freezeDaysPerMonth: number;
  days: Day[];
  isAtRisk: boolean;
  milestone: number;
}

export enum StreakStatus {
  NON_STREAK_DAY = 'NON_STREAK_DAY',
  FREEZE_DAY = 'FREEZE_DAY',
  STREAK_DAY = 'STREAK_DAY',
}

export enum StreakState {
  NEW = 'NEW',
  ONGOING = 'ONGOING',
  MAINTAINED = 'MAINTAINED',
  EXPIRED = 'EXPIRED',
}
