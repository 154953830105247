import { ImageModel } from '../image';
import { UploadFileModel } from '../file';
import { CourseModel } from '../course';

export type TemplatePricingPlan = 'FREE' | 'FULL_IMMERSION' | 'SELF_STUDY';

export const TEMPLATE_PRICING_PLAN_MAP = {
  FREE: 'Free',
  FULL_IMMERSION: 'Full-Immersion',
  SELF_STUDY: 'Self-Study',
};

export interface Template {
  plan: TemplatePricingPlan;
  name: string;
  level: string;
  file?: UploadFileModel;
  uuid: string;
  thumbnail?: ImageModel;
  topic?: TemplateTopic[];
  templateUrl?: string;
  highlighted_fields: any;
}

export interface TemplateDetails {
  description: string;
  file: UploadFileModel;
  uuid: string;
  plan: TemplatePricingPlan;
  level: string;
  name: string;
  recommendedCourses: CourseModel[];
  thumbnail: ImageModel;
  topics: TemplateTopic[];
}

export interface TemplateTopic {
  id: string;
  name: string;
  url?: string;
}

export interface TemplateListingModel {
  templates: Template[];
  totalItems: number;
}

export type TemplateListingAbstractListingModel = Omit<TemplateListingModel, 'templates'> & { itemsList: Template[] };
