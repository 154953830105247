export const COMPANIES_KEY = 'company';

export const COMPANY_B2C_KEY = 'companyB2C';

export const enum B2B_SEARCH_TERM {
  B2B = 'B2B',
  Business = 'Business',
  Team = 'Team',
  Enterprise = 'Enterprise',
}
