import { AdminCaptionLanguageModel } from '../admin-caption';

export const LanguagesMapping: AdminCaptionLanguageModel[] = [
  { languageCode: 'ar-SA', languageName: 'Arabic' },
  { languageCode: 'cs-CZ', languageName: 'Czech' },
  { languageCode: 'zh-CN', languageName: 'Chinese' },
  { languageCode: 'da-DK', languageName: 'Danish' },
  { languageCode: 'nl-BE', languageName: 'Dutch' },
  { languageCode: 'en-US', languageName: 'English' },
  { languageCode: 'fi-FI', languageName: 'Finnish' },
  { languageCode: 'fr-FR', languageName: 'French' },
  { languageCode: 'de-DE', languageName: 'German' },
  { languageCode: 'he-IL', languageName: 'Hebrew' },
  { languageCode: 'hi-IN', languageName: 'Hindi' },
  { languageCode: 'hu-HU', languageName: 'Hungarian' },
  { languageCode: 'id-ID', languageName: 'Indonesian' },
  { languageCode: 'it-IT', languageName: 'Italian' },
  { languageCode: 'ja-JP', languageName: 'Japanese' },
  { languageCode: 'ko-KR', languageName: 'Korean' },
  { languageCode: 'no-NO', languageName: 'Norwegian' },
  { languageCode: 'pl-PL', languageName: 'Polish' },
  { languageCode: 'pt-PT', languageName: 'Portuguese' },
  { languageCode: 'ro-RO', languageName: 'Romanian' },
  { languageCode: 'ru-RU', languageName: 'Russian' },
  { languageCode: 'sk-SK', languageName: 'Slovak' },
  { languageCode: 'es-ES', languageName: 'Spanish' },
  { languageCode: 'sv-SE', languageName: 'Swedish' },
  { languageCode: 'th-TH', languageName: 'Thai' },
  { languageCode: 'tr-TR', languageName: 'Turkish' },
  { languageCode: 'uk-UA', languageName: 'Ukrainian' },
];
