export enum LessonContentTypeEnum {
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  MULTIMEDIA_EXTERNAL_URL = 'MULTIMEDIA_EXTERNAL_URL',
  MULTIMEDIA_ZIP_URL = 'MULTIMEDIA_ZIP_URL',
  MULTIMEDIA_ZIP = 'MULTIMEDIA_ZIP',
  PDF = 'PDF',
  DOWNLOAD = 'DOWNLOAD',
  QUIZ = 'QUIZ',
  EXAM = 'EXAM',
}
