import { PriceModel } from './price.model';
import { PricingContextEnum } from '../../permission/enum';
import { PricesModel } from './prices.model';
import { ImageModel } from '../../image';

export enum RepeatIntervalEnum {
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export enum PricingPlanEnum {
  EMPTY = 0,
  FREE = 'FREE',
  ONE_TIME = 'ONE_TIME',
  SUBSCRIPTION = 'SUBSCRIPTION',
  MONTHLY_SPLIT = 'MONTHLY_SPLIT',
}

export enum PricingPlanFlowEnum {
  THANK_YOU_PAGE = 'THANK_YOU_PAGE',
  COURSE_PLAYER = 'COURSE_PLAYER',
  CUSTOM_URL = 'CUSTOM_URL',
}

export interface PricingPlanFlow {
  endpoint: PricingPlanFlowEnum;
  endpointUrl: null | string;
}

export interface PricingPlanRedirect {
  endpoint: PricingPlanFlowEnum;
  endpointUrl: null | string;
  courseUrl: null | string;
}

export interface PricingPlanModel {
  id: number;
  type: PricingContextEnum;
  courseId: number;
  bundleId: number;
  plan: PricingPlanEnum;
  price?: PriceModel;
  prices: PricesModel;
  daysUntilExpiry?: number;
  intervalLength?: RepeatIntervalEnum;
  freeTrialEnabled?: boolean;
  freeTrialPeriod?: number | null;
  customFirstPaymentEnabled?: boolean;
  customFirstPayment?: PriceModel;
  intervalValue?: number;
  label: string;
  primaryPlan: boolean;
  coursePriceId: number;
  bundlePriceId: number;
  afterPurchaseFlow: PricingPlanFlow;
  nextPaymentAttempt: null | string;
  bundle?: {
    name: string;
    slug: string;
    image: ImageModel;
  };
}

export interface PreviewPayment {
  pricingPlan: PricingPlanModel;
  migrationCost: MigrationCost;
  paymentDueTaxes: PaymentDueTax[];
}

export interface MigrationCost {
  paymentDue: PaymentDue;
  charge: Charge;
  proratedAdjustment: ProratedAdjustment;
  credit: Credit;
  paymentDueDiscount: PaymentDueDiscount;
}

interface PaymentDue {
  amount: number;
  currency: string;
}

interface Charge {
  amount: number;
  currency: string;
}

interface ProratedAdjustment {
  amount: number;
  currency: string;
}

interface Credit {
  amount: number;
  currency: string;
}

interface PaymentDueDiscount {
  amount: number;
  currency: string;
}

interface PaymentDueTax {
  value: Value;
  rate: Rate;
}

interface Value {
  amount: number;
  currency: string;
}

interface Rate {
  value: string;
  name: string;
  label: string;
}
