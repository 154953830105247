export enum BundleEnrollmentStatusEnum {
  NEW = 'NEW',
  STARTED = 'STARTED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  PASSED = 'PASSED',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  DELETED = 'DELETED',
  FINISHED = 'FINISHED',
}
