import { DatadogConfigInterface } from '../datadog/datadog-config.interface';
import { InjectionToken } from '@angular/core';

export interface Environment {
  appVersion: string;
  appVersionSync: boolean;
  production: boolean;
  envName: string;
  theme: string;
  build: string;
  apiUrl: string;
  adminPanelUrl: string;
  teamsUrl: string;
  dashboardPanelUrl: string;
  recaptchaClientKey: string;
  jwPlayerLibUrl: string;
  paypalClientId: string;
  hotjarTrackingCode: string;
  gtmCode?: string;
  gtmUrl?: string;
  stripePublishableKey: string;
  twigAdminEnabled: boolean;
  serviceWorker?: boolean;
  helpScoutId?: string;
  marketingSiteUrl: string;
  cookieDomain: string;
  cancellationSurveyUrl: string;
  datadog?: DatadogConfigInterface;
  secretKeyAES?: string;
  b2bAdminPanelUrl?: string;
  klaviyoPublicKey?: string;
  helpScoutB2bStudentId?: string;
  helpScoutFICoursePlayerId?: string;
  helpScoutFIDashboardId?: string;
  timeSpentIntervalFeature?: boolean;
  timeSpentUserIdleTimeoutMs?: number;
  elasticSearchEndpoint?: string;
  maxioPublicKey?: string;
  maxioServerHost?: string;
  maxioCreditCardsEnabled?: boolean;
  cookieyesId?: string;
  welcomeSurveyUrl: string;
  welcomeTourEnabled?: boolean;
  klaviyoTrackingDomains?: string[];
  onePageCheckout?: string;
}

export const ENVIRONMENT_TOKEN = new InjectionToken<Environment>('ENVIRONMENT');
