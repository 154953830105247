export enum ParameterCodeEnum {
  DATE_FORMAT = 'DATE_FORMAT',
  DATETIME_FORMAT = 'DATETIME_FORMAT',
  PAGINATION_LIMIT = 'PAGINATION_LIMIT',
  MAX_UPLOAD_FILE_SIZE = 'MAX_UPLOAD_FILE_SIZE',
  EXAM_RETAKE_PRICE = 'EXAM_RETAKE_PRICE',
  LOGO_LINK = 'LOGO_LINK',
  STRIPE_TAX_SWITCH = 'STRIPE_TAX_SWITCH',
  LMS_TAX_SWITCH = 'LMS_TAX_SWITCH',
  SUPPORT_EMAIL = 'SUPPORT_EMAIL',
}
