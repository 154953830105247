import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EncryptDecryptService } from '../domain/crypto.service';
import { CurrentUserStorage } from '@lms/shared/storages';

@Component({
  selector: 'app-user-code',
  templateUrl: './user-code.component.html',
  styleUrls: ['./user-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCodeComponent {
  id = this.userStore.getCurrentUser()?.id.toString() || '';

  constructor(
    public EncryptDecryptService: EncryptDecryptService,
    private userStore: CurrentUserStorage,
  ) {}
}
