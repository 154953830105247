import { Injectable } from '@angular/core';

const NOTIFICATION_SHOWN_FLAG = 'ONE_TIME_LOGIN_SUBSCRIPTION_NOTIFICATION_SHOWN';
@Injectable({ providedIn: 'root' })
export class OneTimeLoginNotificationStorage {
  setValue(): void {
    localStorage.setItem(NOTIFICATION_SHOWN_FLAG, '1');
  }

  alreadyShown(): boolean {
    return localStorage.getItem(NOTIFICATION_SHOWN_FLAG) === '1';
  }

  deleteValue(): void {
    localStorage.removeItem(NOTIFICATION_SHOWN_FLAG);
  }
}
