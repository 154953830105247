import { Injectable } from '@angular/core';

export const URL_AFTER_AUTH = 'returnAfterAuthUrl';

@Injectable({ providedIn: 'root' })
export class ReturnUrlAfterLoginStorage {
  setValue(value: string): void {
    localStorage.setItem(URL_AFTER_AUTH, JSON.stringify(value));
  }

  getValue(): string | null {
    const value = localStorage.getItem(URL_AFTER_AUTH);
    return value !== null && value !== 'undefined' ? JSON.parse(value) : null;
  }

  deleteValue(): void {
    localStorage.removeItem(URL_AFTER_AUTH);
  }
}
