import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';
import { NgZone } from '@angular/core';

const DEFAULT_IDLE_TIME: number = 2 * 60 * 1000;
const DEFAULT_STEP_MS: number = 500;
export class StopWatch {
  private stopWatchValue = 0;
  private isStopWatchPaused = false;
  private isIdleTimerPaused = false;
  private intervalId: number | null = null;
  private setTimeoutId: number | null = null;
  private ngZone: NgZone;

  constructor(ngZone: NgZone) {
    this.ngZone = ngZone;
    this.start();
    this.startIdleTimer();
  }

  getCurrentValue(): number {
    return this.stopWatchValue;
  }

  start(): void {
    if (this.intervalId) {
      return;
    }

    this.intervalId = setInterval(() => {
      this.ngZone.run(() => {
        if (!this.isStopWatchPaused) {
          this.stopWatchValue += DEFAULT_STEP_MS;
        }
      });
    }, DEFAULT_STEP_MS);
  }

  reset(): void {
    this.stopWatchValue = 0;
  }
  stop(): void {
    if (this.setTimeoutId) {
      clearTimeout(this.setTimeoutId);
    }

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  startIdleTimer(): void {
    this.isStopWatchPaused = false;
    this.isIdleTimerPaused = false;
    this.setTimeoutId = setTimeout(() => {
      this.ngZone.run(() => {
        if (this.isIdleTimerPaused) {
          return;
        }

        this.isStopWatchPaused = true;
      });
    }, DEFAULT_IDLE_TIME);
  }

  resetIdleTimer(): void {
    if (this.isIdleTimerPaused) {
      return;
    }

    this.isStopWatchPaused = false;

    if (this.setTimeoutId) {
      clearTimeout(this.setTimeoutId);
      this.setTimeoutId = null;
    }

    this.startIdleTimer();
  }

  pauseIdleTimer(): void {
    this.isStopWatchPaused = false;
    this.isIdleTimerPaused = true;

    if (!this.setTimeoutId) {
      return;
    }

    clearTimeout(this.setTimeoutId);
    this.setTimeoutId = null;
  }
}
